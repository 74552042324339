<template>
  <div>
    <b-card
      :header="header"
      header-text-variant="white"
      align="left"
      class="avify-config-user-card"
    >
      <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center">
        <b-media vertical-align="center">
          <template v-slot:aside>
            <img v-if="mediaImg == 'icon_key'" src="../../assets/icons/icon_key.png" width="32" alt="icon-key">
          </template>
          <h5 class="media-body-title mt-0">{{ mediaTitle }}</h5>
          <span class="text-sm">{{ mediaText }}</span>
        </b-media>
        <b-button variant="secondary" class="mt-4 mt-sm-0" @click="$emit('onClickButton')">{{ buttonText }}</b-button>
      </div>
      <slot name="config-user-card-body"></slot>
    </b-card>
    <DeleteAccount />
  </div>
</template>

<script>
import DeleteAccount from '../DeleteAccount/DeleteAccount.vue'
export default {
  name: 'ConfigUserCard',
  props: {
    header: String,
    mediaImg: String,
    mediaTitle: String,
    mediaText: String,
    buttonText: String
  },
  components: { DeleteAccount }
}
</script>

<style lang="less">
  @import 'configUserCard.less';
</style>
