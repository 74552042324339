<template>
  <div id="config-user-account">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container fluid="sm" class="config-user-account-container mt-4">
      <h1 class="d-flex">Configuración</h1>
      <b-row>
        <b-col cols="12">
          <config-user-card
            header="Inicio de sesión"
            media-img="icon_key"
            media-title="Cambiar contraseña"
            media-text="Se recomienda usar una contraseña segura que no uses para ningún otro sitio"
            button-text="Cambiar"
            @onClickButton="showEditPasswordForm = !showEditPasswordForm"
          >
            <template v-slot:config-user-card-body v-if="showEditPasswordForm">
              <validation-observer ref="registerForm" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onChangePassword)" autocomplete="off" novalidate>
                <hr>
                  <b-row>
                    <b-col cols="12" sm="4">
                      <validation-provider name="contraseña actual" rules="required" v-slot="validationContext">
                        <b-form-group id="current-password" label-for="input-username">
                          <b-form-input
                            id="current-password"
                            name="current-password"
                            type="password"
                            v-model="changePassword.currentPassword"
                            :state="getValidationState(validationContext)"
                            placeholder="Contraseña actual"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" sm="4">
                      <validation-provider name="contraseña" v-slot="validationContext" vid="confirmation" rules="required|password">
                        <b-form-group id="input-password" label-for="input-password">
                          <b-form-input
                            id="input-password"
                            name="input-password"
                            type="password"
                            v-model="changePassword.newPassword"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-password"
                            placeholder="Contraseña"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" sm="4">
                      <validation-provider name="confirma tu contraseña" rules="required|confirmed:confirmation" v-slot="validationContext">
                        <b-form-group id="input-repeated-password" label-for="input-repeated-password">
                          <b-form-input
                            id="input-repeated-password"
                            name="input-repeated-password"
                            type="password"
                            v-model="repeatedPassword"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-repeated-password"
                            placeholder="Confirma tu contraseña"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                <hr>
                  <b-row>
                    <b-col cols="12" sm="2">
                      <div class="text-center">
                        <b-button block type="submit" variant="primary">
                          Guardar cambios
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </template>
          </config-user-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import ConfigUserCard from '@/components/Card/ConfigUserCard'

export default {
  name: 'ConfigUSerAccount',
  components: {
    Header,
    ConfigUserCard
  },
  data () {
    return {
      showEditPasswordForm: false,
      changePassword: {
        currentPassword: null,
        newPassword: null
      },
      repeatedPassword: null
    }
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  },
  methods: {
    onChangePassword () {
      this.lockScreen()
      this.$store.dispatch('updateUserPassword', this.changePassword)
        .then(() => {
          this.showEditPasswordForm = false
          this.changePassword = {
            currentPassword: null,
            newPassword: null
          }
        })
    }
  }
}
</script>
