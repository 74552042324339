<template>
  <div class="delete-account">
    <p
      class="delete-account--link"
      @click="onClickDeleteAccount"
    >
      Eliminar mi cuenta de Avify
    </p>
    <modal-component
      id="confirm-deletion-modal"
      title="Eliminar cuenta"
      size="md"
      :confirmationClose="false"
      deleteButton
      delete-text="Eliminar cuenta"
      ok-title="Cancelar"
      @deleted="onConfirmedDeleteAccount"
      @confirmed="onCancelDeleteAccount"
    >
      <b-row class="text-center">
        <b-col class="modal-icon">
          <img width="64" :src="require('@/assets/icons/alert.svg')">
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col>
          ¿Estás seguro de que quieres eliminar tu cuenta de Avify?
          <br>
          Este cambio es permanente y no podrás recuperar tu cuenta.
        </b-col>
      </b-row>
    </modal-component>
</div>
</template>

<script>
import ModalComponent from '@/components/Modal/ModalComponent'

export default {
  name: 'DeleteAccount',
  components: {
    ModalComponent
  },
  methods: {
    onClickDeleteAccount () {
      this.$bvModal.show('confirm-deletion-modal')
    },
    onCancelDeleteAccount (modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
    },
    onConfirmedDeleteAccount (modalId) {
      this.lockScreen()
      this.$store.dispatch('user/deleteMe')
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide(modalId)
            this.unlockScreen()
            this.$router.push({ name: 'logout' })
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
  @import "../../style/colors";
  .delete-account {
    text-align: left;
    margin-top: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
    color: @color_radiant_pink;
    cursor: pointer;

  }
  .modal-icon {
    padding: 1rem 0 2rem 0;
  }
</style>
